import React from 'react';
import * as style from './ManufacInfo.module.scss';
import StarRatings from 'react-star-ratings';
import InfoPopup from '../../../UI/InfoPopup/InfoPopup';
import Features from './Features/Features';
import ExplanationTxt from './ExplanationTxt';
import Basics from './Basics/Basics';
import Prices from './Prices/Prices';
import ManufacImgSlider from '../../ManufacDetail/ManufacImgSlider/ManufacImgSlider';
import smoothscroll from 'smoothscroll-polyfill';
import scrollToEl from '../../../../scripts/scrollTo';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling"; 
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import ImgLocation from '../../../../images/icons/location.svg';
import LeafletAssetProvider from '../../../LeafletAssetProvider/LeafletAssetProvider';


class ManufacPrev extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExplanations: false,
    }
    this.showExplanationsHandler = this.showExplanationsHandler.bind(this);
    this.hideExplanationsHandler = this.hideExplanationsHandler.bind(this);
    this.reportRef = React.createRef();
    this.topRef = React.createRef();
  }

  showExplanationsHandler(){
    this.setState({showExplanations: true});
  }

  hideExplanationsHandler(){
    this.setState({showExplanations: false});
  }

  componentDidMount() {
    void GestureHandling; // calm down eslint (... is defined but never used)
    smoothscroll.polyfill();
    // window.scrollTo(0, this.topRef.current.offsetTop)
    if(window.location.hash === "#top" && this.topRef) {
      this.topRef.current.scrollIntoView({behavior: 'auto'});
    }
  }

  render() {
    const {data, reviewsAmount, averageRating} = this.props;
    const products = data.products.map((product, i) =>
      <li key={i}><span className={[style.Bullet, "blackBckg"].join(' ')}></span><span>{product}</span></li>
    );

    const averageStars = null;
      // <StarRatings
      //   starDimension="15px"
      //   starSpacing="1px"
      //   rating={averageRating}
      //   starRatedColor="rgb(145, 73, 66)"
      //   starEmptyColor="grey"
      //   numberOfStars={5}
       
      // />; 

    const reportsString = `Erfahrungsbericht${reviewsAmount>1 || reviewsAmount === 0 ? 'e' : ''}`;
    return (
      <div className={[style.ManufacPrev, this.props.showDetailsClass].join(' ')} ref={this.topRef}>
        <div className={[style.Anchor]} id="top">&nbsp;</div>
        <div className={[style.HeadArea].join(' ')}>
          <h2 className={style.Name}>{data.name}</h2>
          <div className={style.ReviewPrev} >
            {averageStars} 
            <span 
              onClick={ () => { scrollToEl(this.reportRef.current, 40); } } 
              //notClickable-Class: when ManufafDetails are shown this span can't be clicked
              className={[style.ReviewPrevClickArea].join(' ')}
            >
              {reviewsAmount} {reportsString}
            </span>
          </div>
          
          <div className={style.Line}></div>
          <ul className={style.Products}>{products}</ul>
        </div>
        {/* <AddressBlock data={data}/> */}
        <ManufacImgSlider 
            className={this.props.sliderClass}
            detailFluid={this.props.detailFluid}
            sliderImages={this.props.sliderImages}
            manufacName={data.name}
        />

        <div className={style.Description}>
          <p><strong>Beschreibung von {data.name}</strong><br /><span dangerouslySetInnerHTML={{__html: data.description }}></span></p>
        </div>
        <div className="locationWrapper">
          <p><strong>Standort</strong></p>
          {typeof window !== 'undefined'  ?
          <>
            <LeafletAssetProvider /> 
            <MapContainer 
              className={style.Map}
              gestureHandling={true}
              gestureHandlingOptions={{
                duration: 2000 //2 secs
              }}
              gestureHandlingText={{
                touch: "Zwei Finger zum Bewegen oder Zoomen innerhalb Karte",
                scroll: "Bitte STRG-Taste gedrückt halten um Karte zu zoomen",
                scrollMac: "Bitte \u2318 gedrückt halten um Karte zu zoomen"
              
              }}
              center={data.coordinates} 
              zoom={8}
              useFlyTo={true}  
            > 
              <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution="&copy; <a target=&quot;_blank&quot; href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
              />
              <Marker position={data.coordinates}>
                <Popup>
                  <h3>{data.name}</h3>
                  <p>{data.address}</p>
                </Popup>
              </Marker>
          
            </MapContainer>
          </> : null }
          <div className={style.Address}> <img alt="address" src={ImgLocation}/>{ data.address } </div> 
 
        </div>
        <Basics clickHandler={this.showExplanationsHandler} data={data} />
        <Features featuresArr={this.props.data.features} featureDesc={this.props.data.featureDesc}/>

        <p className={style.PricesHeadline}><strong>Preise</strong></p>
        <Prices data={this.props.data.prices}/>
        <InfoPopup show={this.state.showExplanations} clickHandler={this.hideExplanationsHandler}>
          <ExplanationTxt />
        </InfoPopup>
        <div className="InfoEnd" ref={this.reportRef}></div>
      </div>
      

      
    );
  }
} 

export default ManufacPrev;
