// extracted by mini-css-extract-plugin
export var Box = "report-module--Box--a2478";
export var CommentLink = "report-module--CommentLink--ab311";
export var Head = "report-module--Head--af8f3";
export var Meta = "report-module--Meta--6f5ff";
export var NoRec = "report-module--NoRec--8e570";
export var ProductText = "report-module--ProductText--79566";
export var Recommended = "report-module--Recommended--cc2a1";
export var ReportWrapper = "report-module--ReportWrapper--addcb";
export var Separator = "report-module--Separator--f7a58";
export var Text = "report-module--Text--3def5";
export var Title = "report-module--Title--42446";
export var YesRec = "report-module--YesRec--f5263";