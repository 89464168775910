// extracted by mini-css-extract-plugin
export var BackButton = "HerstellerTemplate-module--BackButton--793b3";
export var BigImage = "HerstellerTemplate-module--BigImage--2ac7a";
export var Button = "HerstellerTemplate-module--Button--0e800";
export var GoUpBtn = "HerstellerTemplate-module--GoUpBtn--a973d";
export var Head = "HerstellerTemplate-module--Head--6bd3b";
export var ManufacDetail = "HerstellerTemplate-module--ManufacDetail--f86e6";
export var NoReportsText = "HerstellerTemplate-module--NoReportsText--313a1";
export var Opened = "HerstellerTemplate-module--Opened--6af0e";
export var ReportsContainer = "HerstellerTemplate-module--ReportsContainer--7df62";
export var ValidityBtn = "HerstellerTemplate-module--ValidityBtn--3a249";
export var ValidityPopup = "HerstellerTemplate-module--ValidityPopup--cb53d";
export var furtherInformation = "HerstellerTemplate-module--furtherInformation--5d9f0";
export var newReviewButton = "HerstellerTemplate-module--newReviewButton--e757d";
export var reportLongHeadline = "HerstellerTemplate-module--reportLongHeadline--c4c61";
export var reportShortHeadline = "HerstellerTemplate-module--reportShortHeadline--d0c7b";