import React from 'react';
import Slider from "react-slick";
import Img from 'gatsby-image';

import './ManufacImgSlider.scss';


class ManufacImgSlider extends React.Component {
  state = { autoplay: true };
  autoplaySpeed = 5000;
  timeOut = null;

  componentDidMount() {

    //stop autoplay two images were shown
    if(this.props.sliderImages) {
      const that = this;
      this.timeOut = setTimeout(()=>{
        that.disableAutoplay();
      }, this.autoplaySpeed * 2);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  disableAutoplay = () => {
    this.setState({ autoplay:false })
  }

  render() {

    if(!this.props.sliderImages) {
      return null;
    }
  
    let settings = {
      dots: true,
      swipe: true,
      swipeToSlide: true,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: this.state.autoplay,
      // autoplaySpeed: this.autoplaySpeed,
      pauseOnHover: true,
      // fade: true,
      pauseOnfocus: true,
      onSwipe: this.disableAutoplay
    };
    const slides = this.props.sliderImages ? 
      this.props.sliderImages.map((img,i) => 
        <div key={i}>
          <Img 
            className={this.props.className} fluid={img.sliderImg.childImageSharp.fluid}
            alt={`Bild ${i} von ${this.props.manufacName}`}
          />
        </div>
      ) 
      : null;
  
    return (
      <Slider {...settings} >
        {slides}
      </Slider>
    );
  }
}

export default ManufacImgSlider;