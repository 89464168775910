import React from 'react';
import * as style from './Basics.module.scss';
import imgTick from '../../../../../images/icons/tick-mark.svg';
import imgCross from '../../../../../images/icons/cross.svg';

const Basics = ({clickHandler, data}) => {
  const NA = 'k.A.';
  const setClass = (val) => ( typeof val === "undefined" || val ==='' ? style.NA : '');
  const checkMark = (val)  => {
    if(typeof val === "undefined" || val ==='') {
      return NA;
    } else {
      return (val ? 
        <img alt="vorhanden" src={imgTick} className={style.CheckedSymbol} /> : 
        <img alt="nicht vorhanden" src={imgCross} className={style.NotCheckedSymbol} />);
    }
  }

  return (
    <div className={style.Basics}>
      <ul className={['', 'blackBor', ''].join(' ')}>
        <li><strong>Allgemeines</strong></li>
        <li className={setClass(data.customMade)}>
          <span>{checkMark(data.customMade)}</span><span className="">Maßgefertigte Häuser</span> 
          <ul className={[style.BasicExplanation].join(' ')}>
            <li>Fertigt der Hersteller auch individuelle Häuser, die du selbst entworfen hast?</li>
          </ul>
        </li>

        <li className={setClass(data.modularDesign)}>
          <span>{checkMark(data.modularDesign)}</span><span className="">Baukastensystem</span> 
          <ul className={[style.BasicExplanation].join(' ')}>
            <li>
              Kannst du einen fertigen Tiny-House-Baukasten erwerben, 
              mit dem du relativ einfach den Rohbau deines Tiny Houses aufbauen kannst?
            </li>
          </ul>
        </li>

        <li className={setClass(data.buildingShell)}>
          <span>{checkMark(data.buildingShell)}</span><span className="">Rohbau-Anfertigung</span>
          <ul className={[style.BasicExplanation].join(' ')}>
            <li>
            Kannst du dir vom Händler nur den Rohbau deines Hauses
            anfertigen lassen, um den Rest selbst fertig zu bauen? 
            </li>
          </ul>
        </li>

        <li className={setClass(data.autarky)}>
          <span>{checkMark(data.autarky)}</span><span className="">(Teil-)Autarkie möglich</span>
          <ul className={[style.BasicExplanation].join(' ')}>
            <li>
              Bietet der Hersteller auch Tiny Houses an, die ein zumindest
              teilweises, autarkes Wohnen ermöglichen?
            </li>
          </ul>
        </li>
      </ul>
  
      <p className={style.Sizes}>
        <span><strong>Größen</strong><br/></span>
        <span>
          { data.length ? 
          <>
            <span className="">Folgende Längen sind erhältlich: </span> 
            <span className="">{data.length}</span><br />
          </> : null}
          { data.useSpace ? 
          <>
            <span className="">Die Nutzfläche der Häuschen beträgt: </span> 
            <span className="">{data.useSpace}</span
          ></> : null}
          { !data.useSpace && !data.length? 
          <span className="">Leider haben wir noch keine Informationen hierüber.</span>  : null}
        </span>
      </p>
  
    </div>
  );
};
export default Basics;