import React from 'react';
import { Amount, Product, PriceLabels, PriceLabel } from './Prices.module.css';
import formatNumber from '../../../../../scripts/formatNumber';

const Prices = ({ data }) => {
  const priceLabelsArr = data.map(priceLabel => (
    <div className={[PriceLabel, 'blackBor'].join(' ')} key={priceLabel[0]}>
      <div className={[Amount, 'greenCol'].join(' ')}> ab: <strong>{formatNumber(priceLabel[0])} € </strong></div>
      <div className={[Product, 'fontMiddle'].join(' ')}> für: {priceLabel[1]} </div>
    </div>
  ));

  return(
    <div className={[ PriceLabels, ''].join(' ')}>
      {priceLabelsArr}
    </div>
  );
}
export default Prices;