import React from 'react';
import { graphql } from 'gatsby';
import * as style from './HerstellerTemplate.module.scss';
import Report from '../components/Manufac/Report/Report';
import { Link, navigate } from 'gatsby';
import StarRatings from 'react-star-ratings/build/star-ratings';
import Button from '../components/UI/Button/Button';
import InfoPopup from '../components/UI/InfoPopup/InfoPopup';
import ValidityTxt from '../components/Manufac/ManufacDetail/ValidityTxt';
import InfoButton from '../components/UI/InfoButton/InfoButton';
import * as text from '../data/text/comps/manufacDetail.json'
import manufacReviews from '../data/manufac-reviews';
import { getGraphQLimage } from '../scripts/herstellerPage/getGraphQLimage.js';
import Layout from '../components/Layout/Layout';
import Questions from '../components/Manufac/Questions/Questions';
import ManufacInfo from '../components/Manufac/ManufacPage/ManufacInfo/ManufacInfo';
import { replaceUmlautWhitspace, truncate } from '../scripts/helper';
import Seo from '../components/SEO/SEO';


export default class HerstellerDetailTemplate extends React.Component {

  state = {
    showValidityPopup: false
  }

  toggleValidityPopup = () => {
    this.setState((prev) => {
      return {showValidityPopup: !prev.showValidityPopup } 
    });
  };

  goToWebsiteHandler = () => {
    if(window && window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'Manufac Website',
        'event_label': this.props.pageContext.manufac.name,
        'value': 1
      });
    }
  }

  getAverageRating(reviewsData) {
    if(reviewsData.length === 0) {
      return 0;
    }

    let total = 0;
    reviewsData.forEach(rev => {
      total += rev.rating.total;
    });
    return Math.round( total / reviewsData.length );
  }

  render() {
    const data = this.props.pageContext.manufac; // data => manufac
    const imageGraphQlData = this.props.data;
    const manufac = data;
    const manufacNameUrl= replaceUmlautWhitspace(data.name.toLocaleLowerCase());
    const reviewsData = manufacReviews[data.name];
    const reviewsAmount = reviewsData.length;
    const averageRating = this.getAverageRating(reviewsData);
    const show = true; //toggled details
    const initialLoad = this.props.location.key === "initial";

    // debugger;
    const imagesObj = getGraphQLimage(imageGraphQlData, manufac, manufacReviews, true);
    const detailImage = imagesObj.detailImage;
    const sliderImages = imagesObj.sliderImages;
    const reviews = reviewsData.map((rev, i) => {
      return <Report manufacName={data.name} reviewData={rev} key={i} />
    }) || <p>nixe hier</p>;

    const averageStars =
    <div style={{opacity: '0', display:'inline'}} >
      <StarRatings
        starDimension="20px"
        starSpacing="1px"
        rating={averageRating}
        starRatedColor="rgba(145, 73, 66, 1)"
        starEmptyColor="grey"
        numberOfStars={5}
      />
    </div>;

    const reportsHeadline = 
      <>
        <span className={style.reportLongHeadline}>Erfahrungsbericht</span>
        <span className={style.reportShortHeadline}>Bericht</span>
        {reviewsAmount>1 || reviewsAmount === 0 ? 'e' : ''}
      </>;

    
    
    // add https to the link if not provided (otherwise link doesn't work)
    const websiteUrl = data.url.search("http") !== -1 ? data.url : `https://${data.url}`;
    return (
    // <Layout headlines={[manufac.name]} > 
    <>
    <Layout headlines={["Tiny House Hersteller"]} > 
      <ManufacInfo 
        data={data}
        reviewsAmount={reviewsAmount}
        averageRating={averageRating}
        sliderClass={style.BigImage}
        detailFluid={detailImage}
        sliderImages={sliderImages}
      />
      <div className={[style.ManufacDetail, 'XXXfontMiddle', show ? style.Opened : null].join(' ')}>
        {
          this.props.data.wpPost ? 
          <Questions 
            wpCommentsArr={this.props.data.allWpComment.nodes}
            manufacName={data.name}
            postId= {this.props.data.wpPost.id}
            // postId= "5"
          /> :
          null
        }
        <div id="hersteller-review"></div>
        <div className={style.ReportsContainer} ref={this.props.setRef}>
          <div className={style.Head}>
            <h2>{reviewsAmount ? reviewsAmount : "Keine"} {reportsHeadline}</h2>
            {averageStars} <span style={{opacity: '0'}}>{averageRating} von 5 Sternen</span>
            
            <Button color='black' type='narrow' className={[style.newReviewButton, style.Button].join(' ')}>
              <Link 
                to="/hersteller/erfahrungsbericht-verfassen/"
                state={{ manufacName: data.name }}
              >
                Bericht verfassen!
              </Link>
            </Button>
            <InfoButton 
              clickHandler={this.toggleValidityPopup}
              className={[style.ValidityBtn, 'fontSmall'].join(' ')}
              color='black'
            >Echtheit der Bewertungen</InfoButton>
            <InfoPopup 
              show={this.state.showValidityPopup} 
              clickHandler={this.toggleValidityPopup} 
              col='one'
              noBackdrop
              className={style.ValidityPopup}
            >
              <ValidityTxt />
            </InfoPopup>
          </div>
          {
            reviews.length ? 
            reviews : 
            <p className={style.NoReportsText}>
              {text.emptyReport.replace('[Herstellername]', data.name)}
              <br /><br />
              <span 
                dangerouslySetInnerHTML={{__html: text.emptyReportNewsletter}}>
              </span>
              
            </p>
          }
        </div>
      </div> 
      <div className={style.furtherInformation}>
        <h2>Weitere Infos zum Tiny House Hersteller</h2>
        <p>Du hast nicht das gefunden, wonach du suchst? Dann stell doch einfach in der obigen Fragen-Rubrik
         deine Frage. Gerne kannst du uns auch {" "} 
          <Link to="/kontakt/">kontaktieren</Link>, wenn du dir generell
          weitere Angaben zu den Herstellern wünschst.
        </p>
        <p>Weiterhin findest du möglicherweise weitere Infos auf der Website des Tiny House Herstellers.</p>
        <a  
            target='_blank' rel="noopener noreferrer" 
            href={websiteUrl}
            onClick={ (event) => {this.goToWebsiteHandler(event, websiteUrl)} }
          >
            Hier geht es zur Website
        </a> von {data.name}
      </div>
      <Button color='black' type='narrow' className={[style.BackButton, style.Button].join(' ')}>
        {initialLoad ?
          <Link 
          
            to={`/hersteller/`}
            className={style.Button}
          > 
            Zur Hersteller-Übersicht
          </Link>
          :
           <a 
            onClick={(e)=> {e.preventDefault(); navigate(-1)}}
            className={style.Button}
          > 
            ← Zurück zur Übersicht
          </a> 
        }
      </Button> 

    </Layout>
    </>
    );
  }
}

export function Head(props) {
  const data = props.pageContext.manufac;
  // debugger
  const manufacName = data.name;
  const manufacDescription = data.description;
  // avoid double keyphrases (Tiny House) in title
  const websiteTitle = manufacName.toLowerCase().indexOf("tiny house") >= 0 ? 'TinyHouseHelden' : ' Tiny House Helden';
  //remove author of description
  let meta_description = manufacDescription.replace(/\(<i>.*<\/i>\)|<i>.*<\/i>|\(Verfasser:.*\)/g,'');
  meta_description = truncate.apply(meta_description, [160, true]); 

  // debugger;
  return (
    <Seo
      title = {`${manufacName} | ${websiteTitle}`}
      meta_description = {meta_description}
    />

  )
}


// IMPORTANT
// Schema Prefix

// Gatsby Source WordPress prefixes Types from the WPGraphQL Schema with a Wp (this can be changed).
// This means that a GraphQL Type such as Post in WPGraphQL will be WpPost in Gatsby.

//Connection Names

// Connections (lists of nodes) in Gatsby are pluralized with the word all. 
// So instead of root fields post and posts (in WPGraphQL), Gatsby has root 
// fields wpPost and allWpPost.
// more: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-wordpress/docs/features/graphql-wordpress-and-gatsby.md
// graphQl Variables (here: $manuNameRegex): https://www.gatsbyjs.com/docs/graphql-reference/
export const query = graphql`
query manufacTemplateData($manuNameRegex: String) {
# query manufacTemplateData {

  
  allFileSlider: allFile(filter: {relativePath: {regex: "/images/manufacs/.+/detail/"}}, 
    sort: { fields: [name] order: ASC}) {
    
    imagesGroup: group(field: relativeDirectory) {
      sliderImgArr: edges {
       sliderImg: node {
          name
          relativeDirectory
          ...fluidImageDetail
        }
      
      }
    }
  }

  # toDo: maybe other placholder pic for detail component
  placeholderDetail: file(relativePath: {regex: "/images/placeholder/"}) {
    ...fluidImageDetail
  }
  wpPost(slug: {regex: $manuNameRegex}) {
  # wpPost {
    id
  }
  #Todo: properties löschen, die ich nicht brauche
  allWpComment(filter: {commentedOn: {node: {slug: {regex: $manuNameRegex}}}}) {
  # allWpComment {
    nodes {
      id
      content
      date
      type
      approved
      wpParent {
        node {
          id
          content
        }
      }
      children {
        id
      }
      commentedOn {
        node {
          slug
          id
        }
      }
      author {
        node {
          name
        }
      }
    }
  }


}` // End GraphQl


export const fluidImageDetail = graphql`
fragment fluidImageDetail on File {
  childImageSharp {
    fluid(maxWidth: 900, quality: 85) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}`;
// export const commentFields = graphql(`
//   fragment commentFields on wordpress__wp_comments {
//     id
//     content
//     author_name

//     post
    
    
//     path
//     status
//     date
//     wordpress_parent
// }`);

//GraphQl Problem/Issue with Data that doesn't exist
// https://github.com/gatsbyjs/gatsby/issues/2392

/* Solutions
- https://www.gatsbyjs.org/blog/2019-03-04-new-schema-customization/
- create dummy data 

*/