import React from "react";
import * as style from "./Questions.module.scss";
import Button from "../../UI/Button/Button";
import { Link } from "gatsby";

export default (props) => {
  const getCommentById = function ( commentID, comments_list ) {
    for ( let j = 0; j < comments_list.length; j++ ) {
        if ( comments_list[j].id === commentID ) {
            return comments_list[j];
        }
    }
}

  const getCommentDepth = function ( theComment, comments_list ) {
    let depthLevel = 0;
    while ( theComment.wpParent) {
        theComment = getCommentById( theComment.wpParent.node.id, comments_list );
        depthLevel++;
    }
    return depthLevel;
}

  const arrangeComments = function(commentsList) {
    
    let maxDepth = 0;

    // remove not approved comments
    for (let i = commentsList.length - 1; i >= 0; i--) {
      // if (commentsList[i].comment_approved != 1) {
      if (commentsList[i].approved !== true) {
        commentsList.splice(i, 1)
      }
    }

    
    for (let i = 0; i < commentsList.length; i++) {
      
      commentsList[i].comment_children = [];
      let date = commentsList[i].date;
      commentsList[i].date = new Date(date);
      commentsList[i].comment_depth = getCommentDepth(
        commentsList[i],
        commentsList
      );
   
      console.log("commentsList[i].comment_depth", commentsList[i].comment_depth)
      if (getCommentDepth(commentsList[i], commentsList) > maxDepth) {
        maxDepth = getCommentDepth(commentsList[i], commentsList);
      }
      console.log({maxDepth})
      
    }


    for (let i = maxDepth; i > 0; i--) {
      for (let j = 0; j < commentsList.length; j++) {
        if (commentsList[j].comment_depth === i) {
          for (let k = 0; k < commentsList.length; k++) {
            if (commentsList[j].wpParent.node.id === commentsList[k].id) {
              commentsList[k].comment_children.push(commentsList[j]);
            }
          }
        }
      }
    }
    for (let i = commentsList.length - 1; i >= 0; i--) {
      if (commentsList[i].wpParent) {
        commentsList.splice(i, 1);
      }
    }

    return commentsList;
  };

  //deep copy array
  // const arrangedCommentsArr = arrangeComments(JSON.parse(JSON.stringify(props.wpCommentsArr)));
  // const arrangedCommentsArr = arrangeComments(Array.from(props.wpCommentsArr));
  const arrangedCommentsArr = arrangeComments(props.wpCommentsArr);
  const commentsArranged = arrangedCommentsArr.reverse().map((comment, i) => {
    //answers (or in other words: inner/nested comments)
    const childrenEl = comment.comment_children.reverse().map((childrenComment, i) =>(
      <div key={i} className={style.AnswerWrapper}>
        <div className={[style.AnswerMeta, "fontSmall"].join(' ')}>
          Antwort von {" "}
          <strong>{childrenComment.author.node.name}</strong> am {childrenComment.date.toLocaleDateString("de-DE")} um {" "}
          {childrenComment.date.toLocaleTimeString("de-DE")} Uhr:
        </div>
        <div 
          className={style.Answer}
          dangerouslySetInnerHTML={ {__html: childrenComment.content.replace(/<p>|<\/p>/g,'')} }
          // dangerouslySetInnerHTML={ {__html: 'check'} }
        >
        </div>
      </div>
    ));
    
    comment.content = comment.content.replace(/<p>|<\/p>/g,'');
    return (
      <div key={i} className={style.QuestionWrapper}>
        <div className={[style.QuestionMeta, "fontSmall"].join(' ')}>
          Frage gestellt von {" "}
          <span><strong>{comment.author.node.name}</strong></span> am {comment.date.toLocaleDateString("de-DE")} um {" "}
          {comment.date.toLocaleTimeString("de-DE")} Uhr
        </div>
        <div 
          className={[style.Question, "XXXgreenCol"].join(' ')}
        >
          <span dangerouslySetInnerHTML={ {__html: comment.content} } />
          <Link 
            className={[style.AnswerButton, "fontSmall"].join(' ')}
            to="/hersteller/frage-an-hersteller/"
            state={
              { manufacName: props.manufacName,
                postId: props.postId,
                parentId: comment.id,
                question: comment.content,
                question_author: comment.author.node.name 
              }
            }
          >
            Antworten
          </Link>
        </div>
        
        {childrenEl}
      </div>
    ) 
  }); // END outer map

  const noQuestionsText = <p>Es wurden noch keine Fragen zu dem Tiny House Hersteller gestellt.
    Stelle hier öffentlich deine Frage, die entweder von anderen Nutzern oder vom Hersteller selbst beantwortet wird.
  </p>;
  return (
    <section className={style.QuestionsSection}>
      <div className={[style.Head, "section-border"].join(' ')}>
        <h2>Du hast noch Fragen zum Hersteller?</h2>
        <Button color='black' type='narrow' className={[style.NewQuestionsButton].join(' ')}>
              <Link 
                to="/hersteller/frage-an-hersteller/"
                state={
                  { manufacName: props.manufacName,
                    postId: props.postId 
                  }
                }
              >
                Frage stellen!
              </Link>
            </Button>
      </div>
      {/* <h3>{!commentsArranged.length ? "Noch gibt es keine" : null} Fragen zum Hersteller "{props.manufacName}"</h3> */}
      {commentsArranged.length ? commentsArranged : noQuestionsText}
    </section>
  )
}

/* Stehegeblieben/ToDO

- Formular zum eintragen
 - - Reihenfolge der Fragen nochmals checken

Done
- SCHEINT nicht notwendig zu sein: Deep Copy alternative anstatt über JSON sttringify (wegen performance)
- Reihenfolge ändern: Neueste Fragen zuerst (war schon so. Alternatwvi einfach reverse beim mapen benutzen)


*/