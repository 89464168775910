// extracted by mini-css-extract-plugin
export var Address = "ManufacInfo-module--Address--6efbc";
export var Anchor = "ManufacInfo-module--Anchor--d9657";
export var Bullet = "ManufacInfo-module--Bullet--060d8";
export var Description = "ManufacInfo-module--Description--f2e81";
export var HeadArea = "ManufacInfo-module--HeadArea--dffb9";
export var Line = "ManufacInfo-module--Line--7e672";
export var ManufacPrev = "ManufacInfo-module--ManufacPrev--623b1";
export var Map = "ManufacInfo-module--Map--b732d";
export var Name = "ManufacInfo-module--Name--b2ebb";
export var PricesHeadline = "ManufacInfo-module--PricesHeadline--26c55";
export var Products = "ManufacInfo-module--Products--51c60";
export var ReviewPrev = "ManufacInfo-module--ReviewPrev--5c225";
export var ReviewPrevClickArea = "ManufacInfo-module--ReviewPrevClickArea--7c65e";
export var WebsiteButton = "ManufacInfo-module--WebsiteButton--41261";
export var blackBckg = "ManufacInfo-module--blackBckg--a419d";
export var blackBor = "ManufacInfo-module--blackBor--4eaa6";
export var blackCol = "ManufacInfo-module--blackCol--75e0a";
export var brownDarkBckg = "ManufacInfo-module--brownDarkBckg--2df78";
export var brownLightBckg = "ManufacInfo-module--brownLightBckg--141d0";
export var fontMiddle = "ManufacInfo-module--fontMiddle--4a350";
export var fontNormal = "ManufacInfo-module--fontNormal--89d46";
export var fontSmall = "ManufacInfo-module--fontSmall--2efe9";
export var greenCol = "ManufacInfo-module--greenCol--554cf";
export var greyBckg = "ManufacInfo-module--greyBckg--8105c";
export var greyCol = "ManufacInfo-module--greyCol--27ac3";
export var notClickable = "ManufacInfo-module--notClickable--6f691";
export var redCol = "ManufacInfo-module--redCol--a2634";
export var whiteCol = "ManufacInfo-module--whiteCol--c29b3";