import React from 'react';
import { MoreButton } from './ReadMore.module.scss'

// const ReadMore = ({text}) => (
  
// );

class ReadMore extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      readMore:false
    }
    const length = props.maxLength || 400;
    this.text = {
      short: this.props.children.substring(0,length)+"...",
      full: this.props.children
    }
  }

 

  toggleTextHandler = () => {
    // debugger;
    this.setState((prevState) => {
      return { readMore: !prevState.readMore }
    })
  }

  render() {

    return( 
      <>
        <span dangerouslySetInnerHTML={ {__html: this.state.readMore ? this.text.full : this.text.short} }>
       
        </span>
        {" "} 
        <span 
          onClick={this.toggleTextHandler}
          className={MoreButton}
        >
          {(this.state.readMore ? "Weniger" : "Mehr") + " Lesen"}
        </span>
      </>)
  }
}

export default ReadMore;