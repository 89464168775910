import React from "react"

const ValidityTxt = props => (
  <p>
    Alle Bewertungen, die den Anschein erwecken, als könnten sie unecht sein,
    werden von uns genauer geprüft. Dazu nehmen wir unter anderem Kontakt zum
    bewerteten Hersteller und zur bewertenden Person auf. Weiterhin werden alle
    anderen Bewertungen stichprobenartig von uns auf gleiche Weise auf Echtheit
    geprüft.{" "}
  </p>
)

export default ValidityTxt
