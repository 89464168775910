import React from 'react';
import * as style from './features.module.scss';

const Features = (props) => {
  // debugger;
  const featureLIs = props.featuresArr.map((feature, i) =>
      <li key={feature}>{
        feature}
        {props.featureDesc[i] ? <ul><li>{props.featureDesc[i]}</li></ul> : null}
      </li>
  );

  return (
    <ul className={[style.Features].join(' ')}>
      <li><strong>Besonderheiten</strong></li>
      {featureLIs}
    </ul>
  );
}
export default Features;