// extracted by mini-css-extract-plugin
export var Answer = "Questions-module--Answer--88130";
export var AnswerButton = "Questions-module--AnswerButton--aec1e";
export var AnswerMeta = "Questions-module--AnswerMeta--2020e";
export var AnswerWrapper = "Questions-module--AnswerWrapper--799e9";
export var Head = "Questions-module--Head--3762e";
export var NewQuestionsButton = "Questions-module--NewQuestionsButton--4683f";
export var Question = "Questions-module--Question--6eeb8";
export var QuestionMeta = "Questions-module--QuestionMeta--12023";
export var QuestionWrapper = "Questions-module--QuestionWrapper--03160";
export var QuestionsSection = "Questions-module--QuestionsSection--50722";