import React from 'react';
import * as style from './report.module.scss';
import RatingBox from './RatingBox/RatingBox';
import ReadMore from '../../ReadMore/ReadMore';
import { Link } from 'gatsby';
// import ReadMore from 'react-read-more-less';
// import ReadMoreReact from 'read-more-react';

const Report = ({reviewData, manufacName}) => {
  const { 
    ReportWrapper, 
    Text, 
    Box, 
    Head, 
    Title, 
    Recommended, 
    YesRec, 
    NoRec, 
    Meta, 
    Separator, 
    ProductText } 
  = style;

  let recommendedEl = null;
  if(reviewData.recommended) {
    recommendedEl = 
      <span className={[Recommended, YesRec, 'greenCol'].join(' ')}>
        <strong>{reviewData.from} würde {manufacName} weiterempfehlen!</strong>
      </span>
  } else {
    recommendedEl = 
    <span className={[Recommended, NoRec, 'redCol'].join(' ')}>
       <strong>{reviewData.from} würde {manufacName} leider nicht weiterempfehlen!</strong>
    </span>
  }

  // const parseReviewText = (text) => {
  //   let parsedText = text.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
  //   return parsedText;
  // }
  const parsedReviewText = reviewData.text.replace(/(?:\r\n|\r|\n)/g, '<br><br>');

  return(
    <div className={ReportWrapper}>

      <div className={Head}>
        <p className={[Title, 'XXXfontNormal'].join(' ')}><strong>{reviewData.title}</strong></p>
        <p className={Meta}>{reviewData.from} am {reviewData.date} 
          <span className={Separator}>|</span> 
          <span className={ProductText}>{reviewData.product} {reviewData.size && `| ${reviewData.size}`}</span>
        </p>
      </div>
      {/* <RatingBox className={Box} rating={reviewData.rating}/> */}
      <p className={Text}>
          <ReadMore>{parsedReviewText}</ReadMore>
        {recommendedEl}
      </p>
      <Link 
        to="/hersteller/erfahrungsbericht-kommentar/"
        className={style.CommentLink}
        state={{ 
          manufacName: manufacName,
          reviewData: {
            from: reviewData.from,
            date: reviewData.date,
            text: parsedReviewText
          }
        }}
      >
        Kommentieren
      </Link>
    </div>
  );
}
export default Report;